<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <div class="m-2">
        <h2 class="mb-1 text-primary">
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.title') }}
        </h2>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('table.search.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('table.search.entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')" />

            <a
              id="view-report-button"
              class="btn btn-primary"
              :href="getUrl()"
              target="_blank">
              <span class="text-nowrap">{{
                $t('invoice.actions.export')
              }}</span>
            </a>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="itemsFormatted"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
        <template v-slot:head(select)="data">
          <span>
            <b-form-checkbox
              id="toggle-select"
              v-model="allSelected"
              @change="toggleSelectAll" />
            <!-- ? SELECT -->
            <b-tooltip
              target="toggle-select"
              title="Seleccionar / deseleccionar todo"
              triggers="hover"
              :noninteractive="true"
              fallback-placement="counterclockwise">
            </b-tooltip>
          </span>
        </template>

        <template #cell(select)="data">
          <b-form-checkbox-group
            id="checkbox-group"
            v-model="selected">
            <b-form-checkbox :value="data.item.id" />
          </b-form-checkbox-group>
        </template>
        <template #cell(in_helisa)="data">
          <b-badge
            :id="'status-' + data.item.id"
            pill
            class="text-capitalize"
            :variant="`light-${resolveUserStatusVariant(data.item.in_helisa)}`">
            {{ $t(RESOURCES_ELEMENT_NAME + '.status.' + data.item.in_helisa) }}
            <!-- ? STATUS -->
            <b-tooltip
              v-if="data.item.in_helisa == 2"
              :target="`status-${data.item.id}`"
              :title="data.item.error_message"
              triggers="hover"
              :noninteractive="true"
              fallback-placement="counterclockwise">
            </b-tooltip>
          </b-badge>
        </template>

        <template #cell(patient)="data">
          {{ getPatientName(data.item.patient) }}
        </template>

        <template #cell(actions)="data">
          <b-col 
            v-if="data.item.in_helisa == 2"
            class="flex flex-row flex-wrap">
            <b-button
              :id="'send-button-' + data.item.id"
              style="margin: 1px"
              variant="primary"
              @click="send(data.item)">
              <feather-icon icon="SendIcon" />
            </b-button>
            </b-tooltip>
            <!-- ? SEND -->
              <b-tooltip
                :target="`send-button-${data.item.id}`"
                :title="$t(RESOURCES_ELEMENT_NAME + '.actions.send')"
                triggers="hover"
                :noninteractive="true"
                fallback-placement="counterclockwise">
              </b-tooltip>
          </b-col>
        </template>
      </b-table>

      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import moment from 'moment'
import { mapState, mapMutations } from 'vuex'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import qs from 'qs'
import DeleteConfirmation from '../../../core/DeleteConfirmation.vue'
import ElementStoreModule from '../storeModule'

const SIZE_LIST = [50, 100]

export default {
  components: {
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    DeleteConfirmation,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      itemsFormatted: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      selected_element: {},
      currentSelection: {},
      tableColumns: [],
      elementData: {},
      date: undefined,
      arl_id: undefined,
      timer: null,
      selected: undefined,
      allElements: [],
      allSelected: true,
      downloadLink: undefined,
    }
  },
  created() {
    this.RESOURCES_ELEMENT_NAME = 'invoice'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-${this.RESOURCES_ELEMENT_NAME}`
  },
  computed: {
    ...mapState('caseSearch', ['searchData']),
  },
  watch: {
    perPage(val) {
      this.fetchElements()
    },
    currentPage(val) {
      this.fetchElements()
    },
    query(val) {
      this.currentPage = 1
      this.fetchElements()
    },
  },
  async mounted() {
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )
    ;(this.arl_id = parseInt(router.currentRoute.params.arl_id)),
      (this.date = router.currentRoute.params.date)

    await this.fetchElements()

    this.timer = setInterval(() => {
      this.fetchElements()
    }, 10000)

    this.tableColumns = [
      { key: 'select', label: '', thStyle: 'width: 100px;' },
      {
        key: 'arl.name',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.arl`),
        sortable: true,
      },
      {
        key: 'number',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.number`),
        sortable: true,
      },
      {
        key: 'date',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.date`),
        sortable: true,
      },
      {
        key: 'patient',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.patient_name`),
        sortable: true,
      },
      {
        key: 'due_date',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.due_date`),
        sortable: true,
      },
      {
        key: 'in_helisa',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.status`),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.actions`),
        thStyle: 'width: 300px;',
      },
    ]
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
    clearInterval(this.timer)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    getPatientName(patient) {
      if (patient) {
        let name = patient.first_name
        name +=
          patient.second_name && patient.second_name != 'null'
            ? ` ${patient.second_name}`
            : ''
        name += ` ${patient.first_surname}`
        name +=
          patient.second_surname && patient.second_surname != 'null'
            ? ` ${patient.second_surname}`
            : ''
        return name
      }
      return ''
    },
    getUrl() {
      const uri_params = qs.stringify({ s: this.selected }, { indices: false })
      return `${process.env.VUE_APP_BASE_API}/api/v1/billing/rips_report?${uri_params}`
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.selected = [...this.allElements]
      } else {
        this.selected = []
      }
    },
    resolveUserStatusVariant(status) {
      if (status == 1) return 'success'
      if (status == 2) return 'danger'
      return 'secondary'
    },
    filterJson(jsonData) {
      const filterList = this.tableColumns.map((a) => a.key)
      filterList.pop()
      return jsonData.map((v) => filterList.map((j) => v[j]))
    },
    getHeader() {
      const res = {}
      for (let i = 0; i < this.tableColumns.length; i++) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    async send(elementData) {
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/sendElement`
      const _self = this

      elementData.in_helisa = 0

      return store
        .dispatch(method, elementData)
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.send.success`
              ),
              icon: 'SendIcon',
              variant: 'success',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.send.success`
              ),
            },
          })
          return true
        })
        .catch((error) => {
          console.info(error.message)
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.send.failure`
              ),
              icon: 'SendIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
    },
    fetchElements() {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchElements`, {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            arl_id: this.arl_id,
            date: this.date,
            query: this.query,
          },
        })
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total

          if (this.selected === undefined) {
            this.selected = [...response.data.keys]
            this.allElements = response.data.keys
          }
          this.itemsFormatted = this.items.map((item) => ({
            ...item,
            date: moment(item.date).format('DD/MM/YYYY'),
            due_date: moment(item.due_date).format('DD/MM/YYYY'),
          }))
        })
        .catch(() => {
          console.error('Error fetching elements')
        })
    },
    getReport() {
      this.downloadLink = undefined

      const uri_params = qs.stringify({
        queryParams: {
          selected: this.selected,
        },
      })

      console.info(uri_params)

      /*
      store
          .dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/getReport', {
            queryParams: {
              selected: this.selected,
            }
          })
          .then(response => {
            console.info('response.data:', response.data)
            this.downloadLink = response.data.path
          })
          .catch(() => {
            console.error('Error fetching elements')
          })
      */
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
